<template>
    <v-menu bottom offset-x rounded="lg" transition="slide-y-transition" color="primary">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="teal darken-1" class="white--text ma-1">
                <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item link v-if="!item.eliminado " @click="editProvider(item)">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-edit</v-icon> Editar proveedor
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="!item.eliminado && allowPayment" @click="removeProvider(item)">
                <v-list-item-title>
                    <v-icon small class="mr-2" color="red darken-2">fas fa-trash-alt</v-icon> Eliminar proveedor
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="!item.eliminado" :to="{ name: 'ListaEntrada', params: { proveedorId: item.idProveedor } }">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-cart-arrow-down</v-icon> Entradas
                </v-list-item-title>
            </v-list-item>            
            <v-list-item link v-if="allowPayment" :to="{ name: 'ListaEntrada', params: { proveedorId: item.idProveedor, typeEntry: 'pending' }}">
                <v-list-item-title>
                    <v-icon small class="mr-2" color="red darken-2" >fas fa-file-invoice</v-icon> Pagos pendientes
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="allowPayment" :to="{ name: 'ListaPagoProveedor', params: { proveedorId: item.idProveedor } }">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-file-invoice</v-icon> Pagos
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import usuarioDataService from '@/service/usuario/usuarioDataService';


export default {
    name: "ActionsProvider",
    props: {
        item: { type: Object },
        editProvider: { type: Function },
        removeProvider: { type: Function },
    },
    computed: {
        roles(){
            return usuarioDataService.getUserRoles();
        },
        allowPayment() {
            const rolesAllowed = ["ROLE_ADMINISTRATOR_1", "ROLE_ADMIN", "ROLE_COBRANZA"]
            return rolesAllowed.includes(this.roles[0]);
        }
    }
}
</script>